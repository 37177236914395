@import 'primereact/resources/themes/rhea/theme.css';
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";
@import './font_awesome/css/font-awesome.min.css';
@import 'primereact/resources/primereact.min.css';

$color-lessblack: #494c4f;
$color-blue: #276AB7;
$color-red: #F06E64;
$color-green: #0B8E7A;
$color-grey: #A7A7A7;
$color-orange: #F4A261;
$color-tagblue: #008CDC;
$color-transfer: #425170;
$color-filter-selected :#71A5D8;
$color-filter-checkbox-selected :#3C4E60;
$color-badge-danger: #F4B6B6;
$color-badge-warning: #FFD84C;
$color-badge-success: #7BEE94;

.color-background-lessblack {
	background-color: $color-lessblack !important;
}

.color-background-blue {
	background-color: $color-blue;
}

.color-lessblack {
	color: $color-lessblack !important;
}

.color-text-blue {
	color: $color-blue !important;
}

.color-text-tagblue {
	color: $color-tagblue !important;
}


.color-text-red {
	color: $color-red !important;
}

.color-text-green {
	color: $color-green !important;
}

.color-text-grey {
	color: $color-grey !important;
}

.color-text-orange {
	color: $color-orange !important;
}

.color-tag-red {
	background-color: $color-red !important;
}

.color-tag-green {
	background-color: $color-green !important;
}

.color-tag-grey {
	background-color: $color-grey !important;
}

.color-tag-orange {
	background-color: $color-orange !important;
}

.color-tag-blue {
	background-color: $color-blue !important;
}

.color-tag-tagblue {
	background-color: $color-tagblue !important;
}

.color-tag-tagtransfer {
	background-color: $color-transfer !important;
}

html {
	height: 100%;
}

body {
	margin: 0;
	height: 100%;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

#root {
	height: 100%;
}

.box {
	background-color: var(--green-500);
	color: #ffffff;
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-radius: 4px;
	margin-top: 1rem;
	font-weight: bold;
	box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
	background-color: white !important;
	transition: background-color 5000s ease-in-out 0s;
}

@keyframes animate-width {
	0% {
		width: 0;
	}

	100% {
		width: 100%;
	}
}

.fadein {
	animation: fadein 150ms linear;
}

.fadeout {
	animation: fadeout 150ms linear;
}

.navigation-entry {
	width: 200px;
}

.hamburger-menu {
	margin-right: .5rem !important;
	padding: 2px 2px 0 6px;
}

.p-button,
.p-selectbutton .p-button.p-highlight {
	color: white;
	background-color: $color-blue;
	border-radius: 5px;
}

.p-inputtext {
	background-color: white;
	color: black;
	padding: 7px 5px;
}

.fa-badge {
	font-size: 0.6em;
	position: absolute !important;
	right: -5px;
	top: -3px;
}

.btn-hasbadge {
	position: relative !important;

	padding-right: 0px;
}

.awesomeIcon {
	height: 16px;

	.p-badge.p-badge-danger {
		background-color: $color-badge-danger;
	}

	.p-badge.p-badge-warning {
		background-color: $color-badge-warning;
	}

	.p-badge.p-badge-success {
		background-color: $color-badge-success;
	}

	.p-badge {
		top: 2px;
		padding-left: 7px;
		font-size: 0.6rem;
		font-weight: 500;
		min-width: 1.2rem;
		height: 1.2rem;
		line-height: 1.2rem;
	}
}

.sidesheet {
	i {
		padding-top: 4px;
	}

	.p-badge {
		top: 2px;
		left: 2px;
		font-size: 0.6rem;
		font-weight: 500;
		min-width: 1.2rem;
		height: 1.2rem;
		line-height: 1.2rem;
	}
}

.p-divider-solid.p-divider-horizontal:before {
	border-top-style: solid !important;
}

.p-divider-dashed.p-divider-horizontal:before {
	border-top-style: dashed !important;
}

.p-divider-solid.p-divider-vertical:before {
	border-left-style: solid !important;
}

.p-datepicker {
	min-width: 460px !important;
}

.eaa-menuitem-delete {

	.p-menuitem-text,
	.p-menuitem-icon {
		color: red !important;
	}

}

.comments-module {
	.edit-icon {
		margin-top: 1px;
	}

	.editor-changes-checked {
		background-color: #F8F8F8;
	}
}


.provider-activities-form {

	.p-calendar {
		height: 48px;
		width: 200px;
	}

	.p-inputnumber {
		height: 48px;
		padding: 0px;
	}

	.p-checkbox,
	.p-checkbox-box {
		width: 48px;
		height: 48px;
	}
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-radiobutton .p-radiobutton-box.p-highlight {
	background: $color-filter-checkbox-selected !important;
	border-color: $color-filter-checkbox-selected !important;
}

.reminder-dialog {
	max-width: 1080px;

	.p-datepicker {
		min-width: 350px !important;
	}

	.p-dialog-header {
		border-color: #dadada;
	}

	.p-datepicker {
		padding: .25rem !important;
	}

	.p-datepicker-header {
		padding: 5px;
	}

	.p-datepicker table {
		margin: 0px;
	}

	.p-datepicker table td {
		padding: 0px;
	}

	.p-datepicker table td>span {
		width: 2.5rem;
		height: 2rem;
	}
}

.fieldinfo {
	min-height: 70px;
}

.p-dialog-header {
	background: white !important;
	color: black !important;
}

.p-dialog-header-icon {
	background-color: white !important;
	color: black !important;
}


.company-choose-dialog {
	.p-datatable-thead>tr>th {
		background-color: white !important;
		padding: 0px
	}

	.p-button {
		padding: 0.2rem 0.5rem
	}

	.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
		padding: 0.2rem 0.5rem
	}
}


#spitbutton-eaacase {
	.p-button.p-button-icon-only {
		padding: 0.1rem 0;
	}
}


.p-divider.p-divider-horizontal .p-divider-content {
	padding: 0px;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
	border-color: #7B95A3 !important;
}

.p-unselectable-text.p-tabview-selected {
	.p-tabview-nav-link {
		background: white !important;
		color: black !important;

	}
}

.p-unselectable-text {
	.p-tabview-nav-link {
		background: #dddddd !important;
		color: #aaaaaa !important;
	}


}

.p-editor-toolbar {
	background-color: white !important;
}

.editor-changes-checked {
	user-select: text;

	li,
	ol {
		margin: 6px 0;
	}

	h1,
	h2,
	p {
		margin: 4px 0;
		margin-block-start: 5px;
		margin-block-end: 5px;
		min-height: 19px;
	}
}

.ellipsis-icon:hover {
	font-size: 16pt;
	color: $color-blue;

}

.check-icon,
.add-icon,
.del-icon,
.edit-icon,
.open-icon {
	i:hover {
		color: $color-blue;
		font-size: 16pt;
	}
}

.p-accordion-header-link:hover {
	border: 1px solid #494C4F !important;
}

.ql-container {
	box-sizing: border-box;
	font-family: Helvetica, Arial, sans-serif;
	font-size: 16px;
}

#accordion-eaacasecreate,
#createbuttons-caseeventsheet,
#accordion-caseeventsheet {
	.p-accordion-header-link {
		padding: 0.75rem 0.75rem 0.75rem 1rem !important;
		border: 1px solid #494C4F !important;
		background: #494C4F;
		color: white;
	}

	.accordion-tab-intern {
		background: #ce6934 !important;
		border: 1px solid #ce6934 !important;
		margin-right: 10px;

		.p-accordion-header-link {
			background: #ce6934 !important;
			border: 1px solid #ce6934 !important;
		}
	}

	.accordion-tab-transfer {
		background: #425170 !important;
		border: 1px solid #425170 !important;

		.p-accordion-header-link {
			background: #425170 !important;
			border: 1px solid #425170 !important;
		}
	}

	.accordion-tab-event {
		background: $color-lessblack;
		margin-right: 10px;
	}

	.p-accordion-header-text {
		width: 100%;
	}

}

.p-tag {
	background-color: #494C4F;
}

.caseevent-divider {
	.p-button {
		border-radius: 0px;
	}
}

.button-attachments {
	line-height: 2.25rem;

	.p-button {
		color: black;
		background: #DEE2E6 !important;
		border: none;

		.attachment-button-icon {
			color: white;
			padding: 5px 10px;
			background: $color-blue !important;
		}

		.attachment-button-text {
			margin: 2px;
		}
	}

	.p-button:hover {
		color: white;
		background: #ABB0B3 !important;
	}
}

.eaa-multiselect {
	.p-multiselect-label {
		display: flex !important;
		flex-wrap: wrap !important;
		margin-bottom: 0.25rem;
		margin-top: 0.25rem;
	}
}

.eaa-filter-active {
	background-color: $color-filter-checkbox-selected !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
	background: $color-filter-selected !important;
}

.p-datepicker table td>span.p-highlight {
	background: $color-filter-selected !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
	background: $color-filter-selected !important;
}

.eaa-maincontent {
	margin-top: 20px;
	max-width: 1080px;
	width: 100%;
}

.eaa-dataView {
	width: 1480px;
}

.statistics-menubar-selected {

	.p-menuitem-text,
	.p-menuitem-icon {
		font-weight: bolder;
		color: $color-blue !important;
	}

	.statistics-menubaritem-selected .p-menuitem-text {
		font-weight: bolder;
		color: $color-blue !important;
	}
}

.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text {
	color: $color-blue !important;
}

.overviews-menubar-selected {

	.p-menuitem-text,
	.p-menuitem-icon {
		font-weight: bolder;
		color: $color-blue !important;
	}

	.p-submenu-list {
		.p-menuitem-text {
			font-weight: normal;
			color: #666666 !important;
		}

		.overviews-menubaritem-selected .p-menuitem-text {
			font-weight: bolder;
			color: $color-blue !important;
		}
	}
}

.userform-fte {
	.p-datatable-thead>tr>th {
		background-color: $color-lessblack !important;
		color: #dadada !important;
	}
}

.p-tooltip .p-tooltip-text {
	background-color: $color-blue;
	color: white;
}

.eaa-maincontent,
.eaa-dataView {
	margin-top: 20px;

	input {
		background: none;
	}


	.p-datatable-table {
		.p-inputtext {
			color: silver;
			background: none;
		}
	}

	.p-dropdown {
		background: none;
		border-color: rgb(218, 218, 218);
	}

	.p-multiselect {
		background: none;
		border-color: rgb(218, 218, 218);
		padding: 0px;
	}

	.p-column-filter-clear-button {
		width: auto
	}

	.p-datatable-emptymessage>td {
		width: 100%
	}

	.p-datatable-thead>tr>th {
		background-color: $color-lessblack !important;
		color: #dadada !important;
	}

	.p-sortable-column.p-highlight .p-sortable-column-icon {
		color: $color-filter-selected !important;
	}

	.p-datatable .p-sortable-column .p-sortable-column-badge {
		background: $color-filter-selected !important;
	}

	.p-sortable-column {
		.p-sortable-column-icon {
			color: white !important;
		}
	}

	.p-column-filter-menu-button {
		color: white;
	}

	#statusRowFilterJobtypeTemplate {
		width: 200px;
	}

	#-statusRowFilterTemplate {
		background: none;
	}
}

.eaa-sidecontent {
	width: 400px;

	.border-blue-500 {
		border-color: $color-blue !important;
	}

	.text-blue-500 {
		color: $color-blue !important;
	}
}




.button-cancel {
	border-color: $color-blue;
	background-color: white;
	color: #494c4f !important;
}

.p-button.button-cancel:hover {
	background-color: rgb(232, 232, 232);
}

.eaa-inputdialog {
	width: 1080px;

	ol {
		list-style-position: outside;
		padding: 0px;
		margin: 0 20px 0 20px;
	}

	li {
		padding: 0px;
		margin: 0px;
	}

	.p-dialog-content {
		padding: 0rem 0.5rem 0.5rem 2rem;
	}

	.p-dialog-header {
		padding: 0.5rem 1rem 0rem 2rem;
		border-bottom-width: 0px;
	}
}

.eaa-passworddialog {
	width: 420px;

	.p-dialog-content {
		padding: 0rem 0.5rem 0.5rem 1rem;
	}

	.p-dialog-header {
		padding: 0.5rem 1rem 0rem;
		border-bottom-width: 0px;
	}
}

.filterCreatedCalendar {

	input {
		background: none;
		border: none;
		color: rgba(0, 0, 0, 0)
	}

	button {
		background: none !important;
		border-radius: 0px;
		border-top-right-radius: 5px !important;
		border-bottom-right-radius: 5px !important;
		border-color: #dadada;
	}

	button:hover {
		border-color: #dadada !important;
		background: $color-lessblack;
	}

	.display-dates {
		border: 1px solid #dadada;
		border-width: 1px 0 1px 1px;
		border-top-right-radius: 5px !important;
		border-bottom-right-radius: 5px !important;
	}


}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
	margin: 0px;
}

.relateduser-card,
.associatedcontact-card,
.caseuser-card,
.reminder-card,
.address-card,
.contactperson-card,
.jobopening-card {
	width: 300px;

	.card-content {
		.col {
			padding: 3px;
		}

		.col-fixed {
			padding: 3px 8px;
		}

	}
}

.relateduser-card,
.associatedcontact-card {
	width: 350px;
}

.associatedcontact-card,
.relateduser-card,
.caseuser-card,
.reminder-card,
.jobopening-card {
	.title {
		min-height: 60px;
	}

}

.reminder-card {
	.title {
		height: 40px !important;
	}
}

.eaa-card {

	margin: 5px auto !important;
	background: white;
	margin: 10px;
	border: 1px solid lightgray;
	color: black;
	border-bottom-left-radius: 5px !important;
	border-bottom-right-radius: 5px !important;


	.title {
		background: $color-lessblack !important;
		color: white !important;
		width: 100%;
		margin: 0px;
		padding: 10px;
		height: 70px;
		border-top-left-radius: 5px !important;
		border-top-right-radius: 5px !important;

		.title-icons {
			width: 100px;
			margin-right: 5px;

			i {
				margin-left: 10px;
			}

			.title-pencil {
				margin-top: 1px;
			}
		}
	}


}


.caseeventssheet {
	width: 100%;
}

.card-sheet {
	background: white;

	.p-card-content {
		padding: 0px;
	}

}

#panelRequestDistributionsUserRelationsManual {
	.p-panel {
		padding-top: 0px;
	}

	.p-panel-content {
		border: none;
		padding-top: 0px;
	}
}

.requestdistributionsheet.p-card,
.associatedcontactsheet.p-card,
.relatedusersheet.p-card,
.caserequestsheet.p-card,
.jobopeningsheet.p-card,
.companysheet.p-card,
.caseuserssheet.p-card,
.casepersonsheet.p-card {
	box-shadow: none;
}

.p-splitbutton.p-button-text>.p-button {
	color: white
}

.requestdistributionsheet,
.relatedusersheet,
.associatedcontactsheet,
.jobopeningsheet,
.companysheet,
.caserequestsheet,
.caseuserssheet {
	.col-fixed {
		margin: 0px;
		padding: 0px 0px 2px 2px;
		width: 140px;
	}
}

.casepersonsheet {
	.col-fixed {
		margin: 0px;
		padding: 0px 0px 2px 2px;
		width: 180px;
	}

	.row {
		margin-bottom: 5px !important;
	}
}

.requestdistributionsheet,
.relatedusersheet,
.associatedcontactsheet,
.caserequestsheet,
.jobopeningsheet,
.companysheet,
.caseuserssheet,
.casepersonsheet {
	width: 100%;
	background: white;

	.grid {
		margin: 0px;
		padding: 0px;
	}

	.col {
		margin: 0px;
		padding: 0px 0px 2px 2px;
	}

	.p-card-header {
		margin: 0 5px;
	}

	.p-card-body {
		margin: 5px;
		padding: 0px;
	}
}

.p-carousel .p-carousel-indicators {
	padding: 0.5rem;
}


#mainmenu {
	width: 200px;

	.mainmenu-grid {
		width: 50px;
		transition: width 0.2s;
	}

	.mainmenu-grid:hover {
		width: 220px;
	}
}

.navigation-button-label {
	color: 'white'
}

.navigation-button-icon i {
	color: #494C4F;
	font-size: 20pt;
}


#navigation-headline {
	height: 55px;
	background-color: rgba(0, 0, 0, 0.3);
}

#login-container {
	.p-input-icon-right>i:last-of-type {
		right: 0.7rem
	}

	.p-password {
		background-color: white;
	}

	input,
	.p-password-input {
		padding: 10px 40px 10px 10px;
		width: 100%;
		background-color: white;
	}


}

#fieldset_changePassword {
	.p-fluid {
		width: 100%;
	}

	.p-field {
		margin-bottom: 0.5rem;
	}

	.p-password input {
		width: 100%;
		letter-spacing: 0.1em;
	}

	.p-col-fixed {
		width: 320px;
	}

	.p-fieldset-content {
		padding: 0px;
	}
}

#userInactiveIntervall {
	input {
		background-color: rgb(1, 48, 75) !important;
		background: none !important;
		border: 1px solid darkgray;
		height: 30px;
		width: 30px;
	}

	.p-button.p-button-icon-only {
		width: 1.5rem;
		padding: 0px;
	}

	.p-inputtext-sm {
		padding: 3px 5px;
	}
}